export default {
  title: "remix-i18next (en)",
  description: "A Remix + Vite + remix-i18next example",
  greeting: "Hello",
  contact_us: "Contact us",
  scroll_down: "Scroll down",
  add_to_compare: "Add to compare",
  submitted: "Submitted !",
  choose_an_address: "Choose an address",
  any: "Any",
  section: {
    featured_launches: "Featured Launches",
  },
  contact_sales: {
    heading: "Contact Sales",
    interested: "I am interested in",
    unit_types: "I am interested in the following unit types",
  },
  clear_all: "Clear all",
  unit_size: "Unit Size (sqft)",
  unit_PSF: "Unit PSF",
  filter: "Filter",
  min_size: "Min size",
  min: "Min",
  max: "Max",
  max_size: "Max size",
  enquiry_form: {
    unit_types: "I am interested in the following unit types",
    fields: {
      name: {
        placeholder: "Name",
        error: "Name is required",
      },
      email: {
        placeholder: "Email",
        error: "Email is required",
      },
      phone: {
        placeholder: "Phone",
        error: "Phone is required",
      },
    },
    button: "Enquire Now",
  },
  size: "Size:",
  view: "View",
  view_more: "View more",
  view_all: "View all",
  project_card: {
    price: "Price",
    area: "Area",
    sqft: "sqft",
    sqm: "sqm",
  },
  you_is_looking_for: "{{name}} is looking for project in",
  select_type_you_want: "Select the type {{name}} want to",
  you_budget: "{{name}} budget is",
  let_us_help_you: "Let us help you!",
  leave_your_infomation: "Leave your information",
  our_team_will_contact: "Our team will contact you shortly",
  send: "Send",
  schedule_call: "Schedule call back",
  search_project_placeholder: "Search Project's name",
  search_location: "Search location",
  allowed_to_choose: "Allowed to choose multiple options",
  compare_projects: "Compare Projects",
  compare: "compare",
  location: "Location",
  marketSegment: "Market Segment",
  marketSegment_placeholder: "Any",
  projectType: "Project Type",
  projectType_placeholder: "Any",
  status: "Status",
  status_placeholder: "Any",
  "by-buc": "By BUC",
  "top-soon": "TOP soon",
  completed: "Completed",
  developer: "Developer",
  projectCategory: "Project Category",
  siteArea: "Site Area",
  totalUnits: "Total Units",
  availableUnits: "Available Units",
  price: "Price",
  psf: "PSF",
  tenure: "Tenure",
  expectedTop: "Expected TOP",
  site_area: "Site Area:",
  room_size: "Room Size:",
  project: {
    sections: {
      description: {
        title: "Description",
        description: "Explore the room like viewing the real thing",
      },
      project_details: {
        title: "Project Details",
        description: "",
      },
      virtual_tour: {
        title: "Virtual Tour",
        description: "There are a total of {{total}} virtual tours available",
      },
      site_plan: {
        title: "Site Plan",
        description: "List of Project Facilities",
      },
      location_map: {
        title: "Location Map",
        description: "Discover amenities near this project",
      },
      gallery: {
        title: "Gallery",
        description: "",
      },
      unit_mix: {
        title: "Unit Mix",
        description: "There are a total of {{total}} units in this project",
        table: {
          header_name: {
            unit_type: "Unit Type",
            total_units: "Total Units",
            size: "Size (sqft)",
            unit_mix: "Unit Mix",
          },
        },
      },
      balance_units: {
        title: "Balance Units",
        description: "Pricing of Available Units",
      },
      available_units: {
        title: "Available Units",
        description:
          "Currently, {{soldNumber}} have been sold, representing {{percent}}",
        table: {
          header_name: {
            unit_type: "Unit Type",
            available: "Available",
            size: "Size (sqft)",
            psf: "PSF",
            price: "Price",
          },
        },
      },
      floor_plans: {
        title: "Floorplans",
        description: "There are a total of {{total}} floorplans",
        table: {
          header_name: {
            unit_type: "Unit Type",
            floor_plan: "Floorplan",
            size: "Size (sqft)",
            psf: "PSF",
            price: "Price",
            available: "Available",
            sold: "Sold",
            total_unit: "Total Unit",
            type: "Type",
          },
        },
      },
      sales_transaction: {
        title: "Sales Transaction",
        description: "There are recent transactions",
        total_description: "There are a total of {number} transactions",
        table: {
          header_name: {
            date: "Date",
            unit_type: "Unit Type",
            unit: "Unit",
          },
        },
      },
      scroll_to_enquiry: {
        title: "Sales Gallery is Ready for Appointment",
        description:
          "Click here to book a private appointment directly with us",
        cta_button: "Enquire Now",
      },
    },
    curated_properties: "Properties Curated for You",
  },
  show_more: "Show more",
  show_less: "Show less",
  overall: "Overall",
  click_here: "Click here",
  search: "Search",
  type: "Type",
  range: "Range",
  price_range: "Price Range",
  total: "Total",
  virtual_tours: "Virtual Tours",
  virtual_tour: "Virtual Tour",
  latest_mortgage_rates: "Latest Mortgage Rates",
  invalid_messages: {
    render_video: "Your browser does not support the video tag.",
  },
  titles: {
    location: "Location",
    address: "Address",
    developer: "Developer",
    total_units: "Total Units",
    tenure: "Tenure",
    expectedTOP: "Expected TOP",
    project_category: "Project Category",
  },
  highlight_navigator: {
    latest_promotion: "Latest\nPromotion",
    upcoming_launches: "Upcoming\nLaunches",
    just_top_top_soon: "Just TOP\n& TOP soon",
    featured_launches: "Featured Launches",
  },
  available: "Available",
  sold: "Sold",
  promotion_banner: "Promotion Banner",
  monthly_payment: "Monthly Payment",
  estimated_monthly_payment: "Estimated Monthly Payment",
  latest_promotion: "Latest Promotion",
  added: "Added",
  principal: "principal",
  select_floor_plan: "Select Floorplan",
  error: {
    404: {
      message: "Oops, you’ve lost in space",
      description: "We can’t find the page that you’re looking for",
    },
    500: {
      message: "Internal Server Error",
      description:
        "The server has been deserted for a while. Please be patient or try again later",
    },
    slug: {
      missing: "Slug is missing",
    },
    default: "Something went wrong",
    unknown: "Unknown error",
  },
  footer: {
    company: "Company",
    segments: "Segments",
    contact: "Contact",
    copyright: "Copyright",
    privacy_policy: "Privacy Policy",
    company_menu: {
      about_us: "About us",
      contact_us: "Contact us",
      partner_us: "Partner us",
    },
    segment_menu: {
      RCR: "RCR",
      CCR: "CCR",
      OCR: "OCR",
    },
  },
  page_contact_us: { unit_type: "I am interested in the following unit types" },
  mortgage_calculator: {
    title: "Mortgage Calculator",
    purchase_price: "Purchase Price",
    interest_rate: "Interest Rate",
    residency: "Residency",
    properties_owned: "Properties owned",
    your_current_housing_loan: "Your current housing loan",
    tenure: "Tenure",
    corporate_entity: "Corporate Entity",
    permanent_resident: "Permanent Resident",
    foreigner: "Foreigner",
    singaporean: "Singaporean",
    progressive_payments_calculator: "Progressive payments calculator",
    loan_amount: "Loan amount",
    exercise_fee: "Exercise Fee (S&P)",
    buyer_stamp_duty: "Buyer’s Stamp Duty",
    additional_buyer_stamp_duty: "Additional Buyer’s Stamp Duty",
    booking_fee: "Booking Fee (OTP)",
    subsequent_payment_after_each_stage: "Subsequent payment after each stage",
    initial_payment: "Initial payment",
    total_initial: "Total initial",
    payment_state: {
      stage1: {
        name: "Foundation work (10%)",
        desc: "5% cash/CPF ($50,000)",
        secondDesc: "5% loan",
      },
      stage2: {
        name: "Reinforced framework (10%)",
        desc: "10% loan",
        secondDesc: "",
      },
      stage3: {
        name: "Partition of walls (5%)",
        desc: "5% loan",
        secondDesc: "",
      },
      stage4: {
        name: "Roof / Ceiling (5%)",
        desc: "5% loan",
        secondDesc: "",
      },
      stage5: {
        name: "Door / Window / Electric / Piping (5%)",
        desc: "5% loan",
        secondDesc: "",
      },
      stage6: {
        name: "Carpark / Road / Drain (5%)",
        desc: "5% loan",
        secondDesc: "",
      },
      stage7: {
        name: "TOP (25%)",
        desc: "25% loan",
        secondDesc: "",
      },
      stage8: {
        name: "CSC (15%)",
        desc: "15% loan",
        secondDesc: "",
      },
    },
  },
  related_properties: {
    title: "Properties Curated for You",
    more_project_available: "More project available on our main site",
    some_properties_interested_in: "Some properties you might be interested in",
  },
  calculating: "Calculating",
  from: "from",
  to: "to",
  total_initial: "Total initial",
  submit: "submit",
  copied_phone_number_success: "Phone number copied successfully!",
  price_list: {
    header_name: {
      unit_type: "Unit Type",
      floor_plan: "Floorplan",
      size: "Size (sqft)",
      psf: "PSF",
      price: "Price",
      available: "Available",
      sold: "Sold",
      total_unit: "Total Unit",
      type: "Type",
    },
  },
  sold_out: "Sold out",
  priceList: "price list",
  showflat_appointment_brochure: "Showflat Appointment & Brochure",
  find_out_more_about_this_project: "Find out more about this project",
  enquire_now: "Enquire Now",
  i_am_interested_in: "I am interested in",
  youtube: {
    invalid: "Invalid YouTube URL",
    title: "YouTube video player",
  },
  in: "in",
  let_start: "Let start",
  view_less: "View less",
  min_price: "Min price",
  max_price: "Max price",
  price_list_title: "Price List",
  choose_appointment_time: "Choose appointment time",
  choose_date: "Choose date",
  choose_time: "Choose time",
  choose_appointment_date: "Choose appointment date",
  facetime_confirm_message: `Do you want to call {{phoneNumber}} or use FaceTime? Press Phone to call, Cancel to use FaceTime.`,
  no_unit_type_to_display: "No unit types to display",
  enquire_for_price: "Enquire for price",
  enquire_for_size: "Enquire for size",
  apply_filter: "Apply Filter",
  no_min: "No Min",
  no_max: "No Max",
  modal_confirm: {
    showflat: {
      heading: "Enquiry sent",
      message:
        "Your enquiry has been successfully sent.\nA Sales Rep will get in touch with you shortly",
    },
  },
  phone_error: "Phone number is required",
  sales_team: {
    heading: "Sales Team",
    agent_name: "Agent Name:",
    cea_reg_no: "CEA Reg No:",
    agency_name: "Agency Name:",
    agency_license_no: "Agency License No:",
  },
  privacy_policy: "Privacy Policy",
  at: "at",
  per_sqft: "per sqft",
  unreleased: "Unreleased",
  no_TOP_value: "TBC",
  component_not_found: "Component not found",
  other: "Other",
  disclaimer_notification: "*The information is subject to change and photos/images used are for illustration purposes only.",
  no_unit_available: "No unit available",
  price_list_units: {
    unitStatus: "Status",
    unitName: "Unit",
    floorPlanArea: "Area",
    unitPrice: "Price",
    pricePsf: "Price Psf",
    bedRoomCount: "Beds",
    floorPlanName: "Plan",
    unitTypeTitle: "Type"



  },
  heading: {
    tenure: "Tenure",
    type: "Type",
    top: "T.O.P",
  }
};